import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));

// DASHBOARD: USER
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/users/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/users/UserAccountPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/dashboard/users/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/users/UserEditPage')));

// TAGS
export const TagsListPage = Loadable(lazy(() => import('../pages/dashboard/tags/TagsListPage')));

// CLIENT
export const ClientListPage = Loadable(lazy(() => import('../pages/dashboard/clients/ClientListPage')));
export const ClientDetailsPage = Loadable(lazy(() => import('../pages/dashboard/clients/ClientDetailPage')));
export const ClientNewPage = Loadable(lazy(() => import('../pages/dashboard/clients/ClientNewPage')));

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(lazy(() => import('../pages/dashboard/PermissionDeniedPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));
