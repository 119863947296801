import PropTypes from 'prop-types';
import { alpha, Box } from '@mui/material';

ColorCircle.propTypes = {
  color: PropTypes.string.isRequired,
};

export default function ColorCircle({ color }) {
  return (
    <Box
      sx={{
        ml: -0.75,
        width: 18,
        height: 18,
        bgcolor: color,
        borderRadius: '50%',
        border: (theme) => `solid 2px ${theme.palette.background.paper}`,
        boxShadow: (theme) => `inset -1px 1px 2px ${alpha(theme.palette.common.black, 0.24)}`,
      }}
    />
  );
}
