import { FormControl, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { ColorSinglePicker } from 'src/components/color-utils';

RHFColorSelector.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  formatNumber: PropTypes.bool,
};

export default function RHFColorSelector({ name, helperText, formatNumber, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl {...field} error={!!error}>
          <ColorSinglePicker value={field.value} onChange={(event) => field.onChange(event.target.value)} {...other} />
          <FormHelperText>{error ? error?.message : helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
